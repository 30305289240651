import { useQuery } from '@tanstack/react-query'
import { fetchingActiveUsers } from '../../services'


export const useGetActiveUsers = () => {
  const { data: activeUsers, isSuccess: isActivitiesSuccess, isPending: isActivitiesPending } = useQuery({
    queryFn: () => fetchingActiveUsers(),
    queryKey: ['activeUsers'],
  })

  return {
    isActivitiesSuccess,
    activeUsers: activeUsers || [],
    isActivitiesPending,
  }
}
