import { useQuery } from '@tanstack/react-query'
import { fetchingQuestions } from '../../services'


export const useGetFilteredQuestions = (offset: number, limit: number, filters: { generatedWithAi: boolean }) => {
  const {
    data: questions,
    isPending: isQuestionsPending,
    isSuccess: isQuestionsSuccess,
    refetch: refetchQuestions,
  } = useQuery({
    queryFn: () => fetchingQuestions(offset, limit, filters),
    queryKey: ['questions'],
    staleTime: 5_000_000,
  })

  return {
    questions,
    refetchQuestions,
    isQuestionsPending,
    isQuestionsSuccess,
  }
}
