import React from 'react'
import { Leagues } from '../../types'
import { LeagueRow } from './LeagueRow'


export const LeaguesTable = ({ leagues }: { leagues: Leagues[] }) => {
  const tableHead = ['Name', 'Count Of Users']

  return (
    <div className="h-[90%] overflow-y-auto overflow-x-visible">
      <div className="overflow-x-visible w-[700px] md:w-full">
        <table className="w-full table-fixed mt-[30px]">
          <thead className="p-[20px] border-gray-200 border-y-[1px]">
          <tr className="text-left">
            {tableHead.map((item) => (
              <th
                key={Math.random()}
                className="first:block font-semibold first:py-[10px] text-gray-400"
              >
                {item}
              </th>
            ))}
          </tr>
          </thead>
          {leagues.length !== 0 && (
            <tbody>
            {leagues.map((league) => (
              <LeagueRow
                key={league.id}
                name={league.name}
                countOfUsers={league.countOfUsers}
              />
            ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  )
}
