import React from 'react'
import { CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

interface GraphProps {
  data: { [key: string]: any }[]
  xAxisLabel: string
  yAxisLabel: string
  xAxisDataKey: string
  yAxisDataKey: string
  lineName: string
  lineColor?: string
}

export const Graph: React.FC<GraphProps> = ({
  data,
  xAxisDataKey,
  yAxisDataKey,
  xAxisLabel,
  yAxisLabel,
  lineName,
  lineColor = '#000000',
}) => {
  return (
    <ResponsiveContainer width={'70%'}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray={'3 3'} />
        <XAxis dataKey={xAxisDataKey}>
          <Label value={xAxisLabel} offset={-5} position={'insideBottom'} />
        </XAxis>
        <YAxis dataKey={yAxisDataKey}>
          <Label value={yAxisLabel} angle={-90} position={'insideLeft'} />
        </YAxis>
        <Tooltip />
        <Legend />
        <Line type={'monotone'} name={lineName} dataKey={yAxisDataKey} stroke={lineColor} />
      </LineChart>
    </ResponsiveContainer>
  )
}
