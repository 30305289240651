import React from 'react'


type LeagueRowProps = {
  name: string
  countOfUsers: number
}


export const LeagueRow: React.FC<LeagueRowProps> = ({
  name,
  countOfUsers,
}) => {
  return (
    <>
      <tr className="transition duration-[0.3s] hover:bg-gray-100">
        <td className="my-[10px] block font-semibold">{name}</td>
        <td>
          <div className="w-[100px] flex items-center font-semibold">
            {countOfUsers}
          </div>
        </td>
      </tr>
    </>
  )
}
