import React, { useEffect, useState } from "react";

import { useGetRooms } from "../../hooks/api/useRooms";

import { Pagination, Stack } from "@mui/material";

import { CardBox } from "../../components";
import { RoomItems } from "./RoomItems";

export const Rooms = () => {
  const paginationLimit = 1000;
  const [roomsPagination, setRoomsPagination] = useState({
    offset: 0,
    limit: paginationLimit,
  });
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: rooms,
    refetch: refetchRooms,
    isRefetching,
    isPending
  } = useGetRooms(roomsPagination.offset,  roomsPagination.limit, "");

  const pagesCount = Math.floor(rooms?.roomsCount / paginationLimit);

  const paginationChangeHandler = (_e: any, value: number) => {
    setCurrentPage(value);
    setRoomsPagination({
      ...roomsPagination,
      offset: (value - 1) * paginationLimit,
    });
  };

  useEffect(() => {
    refetchRooms();
  }, [roomsPagination]);

  return (
    <>
      <CardBox>
        <h1 className="text-[25px] font-semibold">Rooms</h1>
      </CardBox>

      <div
        className="
          flex flex-col gap-[8px] mt-[8px] items-start
          md:flex-row lg:h-[calc(100dvh-105.5px)]
        "
      >
        <div
          className="
            w-full flex flex-col justify-between
            h-full md:w-[calc(100%-380px)]
          "
        >
          <RoomItems
            roomItems={rooms?.rooms ?? []}
            loading={isPending || isRefetching}
            refetchRooms={refetchRooms}
          />

          {pagesCount > 1 && !isPending && (
            <div className="flex justify-center items-center py-[10px]">
              <Stack spacing={2}>
                <Pagination
                  count={pagesCount}
                  page={currentPage}
                  size="small"
                  sx={{
                    "& .MuiPaginationItem-root": {
                      "&.Mui-selected": {
                        backgroundColor: "#111827",
                        color: "white",
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: "#111827",
                        color: "white",
                      },
                    },
                  }}
                  onChange={(e, value) => paginationChangeHandler(e, value)}
                />
              </Stack>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
