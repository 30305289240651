import { Leagues } from '../types'

import { apiClient } from '../variables/vars'


const fetchingCountOfUsers = async (leagueId: string) => {
  try {
    const { data } = await apiClient.get(`/customers/${leagueId}`)

    const usersInLeague = data.data

    return usersInLeague.length
  } catch (error) {
    console.log(error)
  }
}


export const fetchingLeagues = async () => {
  try {
    const { data } = await apiClient.get('/leagues')

    const leagues = data.data
    const leaguesArr: Leagues[] = []

    if (data.data) {
      for (const league of leagues) {
        const leagueElement = {
          id: league._id,
          name: league.name,
          countOfUsers: await fetchingCountOfUsers(league._id) || 0,
        }

        leaguesArr.push(leagueElement)
      }
    }

    return leaguesArr
  } catch (error) {
    console.log(error)
  }
}

export const finishingWeeklyTournament = async () => {
  try {
    await apiClient.post('/weekly-tournaments/finish')
  } catch (error) {
    console.log(error)
  }
}
