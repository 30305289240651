import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../context/auth-context'
import { CardBox } from '../../components'
import { Graph } from '../../components/graph/Graph'
import { User } from '../../types'
import { useGetActiveUsers } from '../../hooks'


interface GraphData {
  date: string
  countOfUsers: number
}


const getWeekNumber = (date: Date): string => {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1)
  const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000
  const weekNumber = Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7)
  return `${weekNumber} (${date.getFullYear()})`
}

const countRegisteredUsersByPeriod = (users: User[], period: string): GraphData[] => {
  const map = new Map<string, number>()

  users.forEach(user => {
    const date = new Date(user.createdDate)
    let periodKey: string

    if (period === 'day') {
      periodKey = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
    } else if (period === 'week') {
      periodKey = getWeekNumber(date)
    } else if (period === 'month') {
      periodKey = `${date.getMonth() + 1}-${date.getFullYear()}`
    } else {
      periodKey = date.getFullYear().toString()
    }

    if (map.has(periodKey)) {
      map.set(periodKey, map.get(periodKey)! + 1)
    } else {
      map.set(periodKey, 1)
    }
  })

  return Array.from(map.entries()).map(([createdDate, countOfUsers]) => ({
    date: createdDate,
    countOfUsers,
  }))
}


export const DashboardPage = () => {
  const { fetchingUsers, users } = useContext(AuthContext)
  const { activeUsers } = useGetActiveUsers()

  const [period, setPeriod] = useState<string>('week')

  const dataRegisteredUsers: GraphData[] = countRegisteredUsersByPeriod(users, period)
  const dataActiveUsers: GraphData[] = [...activeUsers].reverse()

  useEffect(() => {
    fetchingUsers(0, 10000)
  }, [])

  const handlePeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPeriod(event.target.value)
  }

  return (
    <div className={'h-[calc(100vh-20px)]'}>
      <CardBox>
        <h1 className={'font-semibold text-[25px]'}>Dashboard</h1>
      </CardBox>
      {(!users || users.length === 0) && (
        <div
          className={'flex items-center justify-center text-gray-400'}
          style={{ height: 'calc(100% - 84px)' }}
        >
          <p>Nothing here yet</p>
        </div>
      )}
      {(users && users.length > 0) && (
        <>
          <div
            className={'flex flex-col items-center text-back-400 m-10'}
            style={{ height: '50%' }}
          >
            <div className={'text-3xl'}>Count of registered users by
            <select value={period} onChange={handlePeriodChange} className={'text-xl ml-2'}>
              <option value={'day'}>Day</option>
              <option value={'week'}>Week</option>
              <option value={'month'}>Month</option>
              <option value={'year'}>Year</option>
            </select>
            </div>
            <Graph
              data={dataRegisteredUsers}
              xAxisDataKey={'date'}
              yAxisDataKey={'countOfUsers'}
              xAxisLabel={'Date'}
              yAxisLabel={'Count'}
              lineName={`Count of users`}
              lineColor={'#ff0000'}
            />
            <div className={'text-xl'}>Total count of users: {users.length}</div>
          </div>
          <div
            className={'flex flex-col items-center text-back-400 m-10'}
            style={{ height: '50%' }}
          >
            <div className={'text-3xl'}>Count of active users by day</div>
            <Graph
              data={dataActiveUsers}
              xAxisDataKey={'date'}
              yAxisDataKey={'countOfUsers'}
              xAxisLabel={'Date'}
              yAxisLabel={'Count'}
              lineName={`Count of users`}
              lineColor={'#00ff00'}
            />
          </div>
        </>
      )}
    </div>
  )
}
