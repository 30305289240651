import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BibleBook, Question, Verse } from '../types'
import { mainPath } from '../variables/vars'


type QuestionsContextTypes = {
  questions: Question[]
  setQuestions: React.Dispatch<React.SetStateAction<Question[]>>
  getQuestionsCount: () => void
  totalQuestionsCount: number
  getVerses: (bookNumber: number, chapterNumber: number) => void
  currentVerses: Verse[]
  findBibleBookName: (id: string, books: BibleBook[]) => string
  versesLoading: boolean
}


const QuestionsContext = React.createContext({
  getQuestionsCount: () => {},
  getVerses: (bookNumber, chapterNumber) => {},
  findBibleBookName: (id, books) => {},
} as QuestionsContextTypes)

export const QuestionsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [questions, setQuestions] = useState<Question[]>([])
  const [currentVerses, setCurrentVerses] = useState<Verse[]>([])
  const [versesLoading, setVersesLoading] = useState(false)
  const [totalQuestionsCount, setTotalQuestionsCount] = useState(0)

  const findBibleBookName = (id: string, books: BibleBook[]) => {
    const foundBook = books.find((element) => element.id === id)

    return foundBook ? foundBook.name : ''
  }

  const getVerses = async (bookNumber: number, chapterNumber: number) => {
    setVersesLoading(true)

    try {
      const { data } = await axios.get(
        `${mainPath}/bible/books/${bookNumber}/chapters/${chapterNumber}/verses`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
      )
      const versesData = data.data

      setCurrentVerses(versesData)
    } catch (error) {
      console.log(error)
    } finally {
      setVersesLoading(false)
    }
  }

  const getQuestionsCount = async () => {
    try {
      const { data } = await axios.get(`${mainPath}/questions-count`)

      setTotalQuestionsCount(data.data.questionsCount)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getQuestionsCount()
  }, [])

  return (
    <QuestionsContext.Provider
      value={{
        questions,
        setQuestions,
        totalQuestionsCount,
        getQuestionsCount,
        getVerses,
        currentVerses,
        findBibleBookName,
        versesLoading,
      }}
    >
      {children}
    </QuestionsContext.Provider>
  )
}


export default QuestionsContext
