import { apiClient } from '../variables/vars'


export const deleteUser = async (id: string) => {
  const res = await apiClient.delete(`/delete-account/${id}`)

  return res
}

export const UsersApiService = {
  deleteUser,
}