import { apiClient } from '../variables/vars'


export const fetchingActiveUsers = async () => {
  try {
    return (await apiClient.get('/customers-activities/active')).data.data
  } catch (error) {
    console.log(error)
  }
}
