import React, { useContext, useState } from 'react'
import { Loader } from 'lucide-react'
import toast from 'react-hot-toast'
import { UserRow } from './UserRow'
import AuthContext from '../../context/auth-context'
import { Button, MainModal } from '../../components'
import { User } from '../../types'
import { useMutation } from '@tanstack/react-query'
import { deleteUser } from '../../services'


export const UsersTable = ({ users }: { users: User[] }) => {
  const { usersLoading, fetchingUsers } = useContext(AuthContext)
  const tableHead = ['Name', 'Created date', 'Email', '']
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false)
  const [userId, setUserId] = useState('')

  const { mutate: deleteUserMutation, isPending: isDeletePending } = useMutation({
    mutationFn: ({ id }: { id: string }) => deleteUser(id),
    onSuccess: () => {
      setOpenDeleteUserModal(false)

      fetchingUsers(0, 10000)
      toast.success('Successfully deleted user')
    },
    onError: () => {
      toast.error('Something went wrong')
    },
  })

  const deleteUserHandler = () => {
    deleteUserMutation({
      id: userId ?? '',
    })
  }

  return (
    <div className="h-[90%] overflow-y-auto overflow-x-visible">
      <div className="overflow-x-visible w-[700px] md:w-full">
        <table className="w-full table-fixed mt-[30px]">
          <thead className="p-[20px] border-gray-200 border-y-[1px]">
          <tr className="text-left">
            {tableHead.map((item) => (
              <th
                key={Math.random()}
                className="first:block font-semibold first:py-[10px] text-gray-400"
              >
                {item}
              </th>
            ))}
          </tr>
          </thead>
          {users.length !== 0 && (
            <tbody>
            {users.map((user) => (
              <UserRow
                key={user.id}
                id={user.id}
                name={user.name}
                createdDate={user.createdDate}
                email={user.email}
                openDeletingModal={() => {
                  setOpenDeleteUserModal(true)
                  setUserId(user.id)
                }}
              />
            ))}
            </tbody>
          )}
        </table>
      </div>

      {users.length === 0 && !usersLoading && (
        <p className="text-center text-gray-300 mt-[100px]">No users yet</p>
      )}
      {users.length === 0 && usersLoading && (
        <div className="h-1/2 flex items-center justify-center text-gray-400">
          <Loader size={20} className="animate-spin"/>
        </div>
      )}

      <MainModal
        title="Deleting user"
        description="Are you sure? This action cannot be undone."
        isOpen={openDeleteUserModal}
        onClose={() => setOpenDeleteUserModal(false)}
        className="w-[400px] relative mt-[-50px] deleting-modal"
      >
        <div className="absolute py-[10px] px-[20px] left-0 right-0 w-full bg-gray-100 min-h-[50px] rounded-b-[15px]">
          <div className="flex justify-end items-center gap-[8px]">
            <Button
              onClick={() => setOpenDeleteUserModal(false)}
              className="bg-white py-[8px] text-gray-700 hover:bg-white"
            >
              Cancel
            </Button>
            <Button
              onClick={deleteUserHandler}
              disabled={isDeletePending}
              className="bg-red-400 py-[8px] hover:bg-red-300"
            >
              Delete
            </Button>
          </div>
        </div>
      </MainModal>
    </div>
  )
}
