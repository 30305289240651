import React, { useContext, useState } from 'react'
import toast from 'react-hot-toast'
import { Loader as LoaderIcon } from 'lucide-react'
import { useMutation } from '@tanstack/react-query'
import { RoomItem } from './RoomItem'
import { Button, Loader, MainModal } from '../../components'
import FullOpenNavbarContext from '../../context/full-open-navbar'
import { deleteRoom, updateRoomReview } from '../../services'
import { RoomItem as RoomItemTypes } from '../../types'


type RoomItemsProps = {
  roomItems: RoomItemTypes[]
  loading: boolean
  refetchRooms: () => void
}


export type Room = {
  id: string
  name: string
  description: string
  isReviewed: boolean
}

export const RoomItems: React.FC<RoomItemsProps> = ({ roomItems, loading, refetchRooms }) => {
  const { isFullOpened } = useContext(FullOpenNavbarContext)
  const [openDeleteRoomModal, setOpenDeleteRoomModal] = useState(false)
  const [openUpdateIsReviewedModal, setOpenUpdateIsReviewedModal] = useState(false)
  const [selectedRoom, setSelectedRoom] = useState<Room | null>(null)
  const [isSoftDelete, setIsSoftDelete] = useState(false)
  const [isReviewed, setIsReviewed] = useState(false)

  const selectRoomHandler = (room: Room) => {
    setSelectedRoom(room)
  }

  const selectToggleStarHandler = (isReviewed: boolean) => {
    setOpenUpdateIsReviewedModal(true)
    setIsReviewed(isReviewed)
  }

  const { mutate: deleteRoomMutation, isPending: isDeletePending } = useMutation({
    mutationFn: ({ id, isSoftDelete }: { id: string, isSoftDelete: boolean }) => deleteRoom(id, isSoftDelete),
    onSuccess: () => {
      setOpenDeleteRoomModal(false)

      refetchRooms()
      toast.success(`Successfully deleted room ${isSoftDelete ? '(with soft deleting)' : ''}`)
    },
    onError: () => {
      toast.error('Something went wrong')
    },
  })

  const { mutate: updateIsReviewedHandlerMutation, isPending: isUpdatePending } = useMutation({
    mutationFn: ({ id, isReviewed }: { id: string, isReviewed: boolean }) => updateRoomReview(id, !isReviewed),
    onSuccess: () => {
      setOpenUpdateIsReviewedModal(false)

      refetchRooms()
      toast.success('Successfully update review')
    },
    onError: () => {
      toast.error('Something went wrong')
    },
  })

  const deleteRoomHandler = () => {
    deleteRoomMutation({
      id: selectedRoom?.id ?? '',
      isSoftDelete: isSoftDelete,
    })
  }

  const updateIsReviewedHandler = () => {
    updateIsReviewedHandlerMutation({
      id: selectedRoom?.id ?? '',
      isReviewed: isReviewed,
    })
  }

  return (
    <>
      <div>
        <p className="text-[24px] font-semibold">List of rooms</p>

        {loading ? (
          <div className="m-auto my-[40px]">
            <Loader/>
          </div>
        ) : (
          <ul
            className={`
            ${isFullOpened && 'lg:grid-cols-1 xl:grid-cols-2'} 
            w-full grid gap-[8px] grid-cols-1 pt-[5px] sm:grid-cols-2
            lg:grid-cols-2 2xl:grid-cols-3
          `}
          >
            {roomItems.map(item => (
              <RoomItem
                key={item.room._id}
                item={item}
                onSelectRoom={selectRoomHandler}
                onOpenDeleteModal={() => setOpenDeleteRoomModal(true)}
                isReviewed={item.room.isReviewed}
                onToggleStar={() => selectToggleStarHandler(item.room.isReviewed)}
              />
            ))}
          </ul>
        )}
      </div>

      {!loading && roomItems.length === 0 && <p className="mx-auto]">No rooms yet</p>}

      <MainModal
        title="Deleting user"
        description="Are you sure? This action cannot be undone."
        isOpen={openDeleteRoomModal}
        onClose={() => setOpenDeleteRoomModal(false)}
        className="w-[400px] relative mt-[-50px] deleting-modal"
      >
        <div className="absolute py-[10px] px-[20px] left-0 right-0 w-full bg-gray-100 min-h-[50px] rounded-b-[15px]">
          <div className="flex justify-end items-center gap-[8px]">
            <Button
              onClick={() => setOpenDeleteRoomModal(false)}
              className="bg-white py-[8px] text-gray-700 hover:bg-white"
            >
              Cancel
            </Button>
            <Button
              onClick={deleteRoomHandler}
              disabled={isDeletePending}
              className="bg-red-400 py-[8px] w-[100px] hover:bg-red-300 disabled:bg-red-300"
            >
              {isDeletePending ? (
                <LoaderIcon className="animate-spin my-[3px]" size={18}/>
              ) : (
                <span>Delete</span>
              )}
            </Button>
          </div>
          <div className="flex items-center mt-[12px]">
            <input
              type="checkbox"
              id="softDelete"
              checked={isSoftDelete}
              onChange={() => setIsSoftDelete(!isSoftDelete)}
              className="mr-[8px]"
            />
            <label htmlFor="softDelete" className="text-gray-700">
              Soft delete (do not delete permanently)
            </label>
          </div>
        </div>
      </MainModal>

      <MainModal
        title="Room review"
        description="Are you sure?"
        isOpen={openUpdateIsReviewedModal}
        onClose={() => setOpenUpdateIsReviewedModal(false)}
        className="w-[400px] relative mt-[-50px] deleting-modal"
      >
        <div className="absolute py-[10px] px-[20px] left-0 right-0 w-full bg-gray-100 min-h-[50px] rounded-b-[15px]">
          <div className="flex justify-end items-center gap-[8px]">
            <Button
              onClick={() => setOpenUpdateIsReviewedModal(false)}
              className="bg-white py-[8px] w-[100px] text-gray-700 hover:bg-white"
            >
              No
            </Button>
            <Button
              onClick={updateIsReviewedHandler}
              disabled={isUpdatePending}
              className="bg-blue-400 py-[8px] w-[100px] hover:bg-blue-300 disabled:bg-blue-300"
            >
              {isUpdatePending ? (
                <LoaderIcon className="animate-spin my-[3px]" size={18}/>
              ) : (
                <span>Yes</span>
              )}
            </Button>
          </div>
        </div>
      </MainModal>
    </>
  )
}
