import { apiClient } from '../variables/vars'
import { BaseServerResponse } from '../lib/utils'
import { RoomItem } from '../types'


export const getRooms = async (offset: number, limit: number, name: string) => {
  try {
    const response = await apiClient.get<BaseServerResponse<RoomItem[]>>(
      `/rooms?offset=${offset}&limit=${limit}&searchQuery=${name}`,
    )

    const data = response.data.data
    const rooms: RoomItem[] = []

    if (data.rooms) {
      for (const item of data.rooms) {
        rooms.push(item)
      }
    }

    return {
      rooms,
      roomsCount: data.roomsCount,
    }
  } catch (error) {
    console.log(error)
  }
}

export const deleteRoom = async (id: string, isSoftDelete: boolean) => {
  if (isSoftDelete) {
    return await apiClient.delete(`/rooms/${id}?isSoftDelete=${isSoftDelete}`)
  } else {
    return await apiClient.delete(`/rooms/${id}`)
  }
}

export const updateRoomReview = async (id: string, isReviewed: boolean) => {
  const res = await apiClient.put(`/rooms/${id}/review`, { isReviewed })

  return res
}


export const RoomsApiService = {
  getRooms,
  deleteRoom,
  updateRoomReview,
}
