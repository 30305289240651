import React from 'react'
import { Star, Trash2 } from 'lucide-react'
import { Room } from './RoomItems'
import { RoomItem as RoomItemTypes } from '../../types'


type RoomItemProps = {
  item: RoomItemTypes
  onSelectRoom: (room: Room) => void
  onOpenDeleteModal: () => void
  isReviewed: boolean
  onToggleStar: () => void
}


export const RoomItem: React.FC<RoomItemProps> = ({
  item,
  onSelectRoom,
  onOpenDeleteModal,
  isReviewed,
  onToggleStar,
}) => {
  return (
    <li
      key={item.room._id}
      className="flex rounded-[14px] bg-white h-[120px]"
    >
      <div
        className="bg-gray-100 w-[120px] h-[120px] max-w-[120px] max-h-[120px]
        rounded-l-[14px] flex items-center justify-center">
        {item.room.avatar ? (
          <img
            src={item.room.avatar}
            alt="Avatar"
            className="w-full h-full max-w-[120px] max-h-[120px] object-cover rounded-l-[14px]"
          />
        ) : (
          <p className="uppercase text-gray-500">{item.room.name[0]}</p>
        )}
      </div>
      <div className="p-[10px] flex flex-col justify-between max-w-[calc(100%-160px)]">
        <p className="font-semibold line-clamp-2" title={item.room.name}>
          {item.room.name}
        </p>
        <p className="text-[14px] text-gray-700">
          Users count: {item.usersCount}
        </p>
      </div>
      <div className="flex flex-col justify-between h-full">
        <div className="w-[40px] mb-auto">
          <button
            onClick={() => {
              onSelectRoom({
                id: item.room._id,
                name: item.room.name,
                description: item.room.description,
                isReviewed: item.room.isReviewed,
              })
              onOpenDeleteModal()
            }}
            className="text-gray-700 text-center p-[8px] transition-all ease-in rounded-[5px] hover:bg-gray-200 hover:text-gray-900"
          >
            <Trash2 size={16}/>
          </button>
        </div>
        <div className="w-[40px] mb-auto">
          <button
            onClick={() => {
              onSelectRoom({
                id: item.room._id,
                name: item.room.name,
                description: item.room.description,
                isReviewed: item.room.isReviewed,
              })
              onToggleStar()
            }}
            className="text-center p-[8px] transition-all ease-in rounded-[5px] hover:bg-gray-200 hover:text-gray-900"
          >
            {!isReviewed && <Star
                size={16}
                fill={'yellow'}
                stroke={'yellow'}
            />}
          </button>
        </div>
      </div>
    </li>
  )
}
